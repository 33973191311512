import React, { useState } from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ url, autoPlay }) => {
    const [isPlaying, setIsPlaying] = useState(autoPlay);

    const playerWrapperStyle = {
        position: 'relative',
        borderTopLeftRadius: '40px',
        borderBottomLeftRadius: '40px',
        width: '363px',
        height: '498px',
        overflow: 'hidden'
    };

    const playerStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
    };

    const buttonStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        color: 'white',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    const handlePlayPause = (event) => {
        event.preventDefault();
        setIsPlaying(!isPlaying);
    };

    return (
        <div style={playerWrapperStyle}>
            <ReactPlayer
                url={url}
                playing={isPlaying}
                width="100%"
                height="100%"
                style={playerStyle}
                loop
                muted
                playsinline={true}
            />
            <button onClick={handlePlayPause} style={buttonStyle}>
                {isPlaying ? 'Pause' : 'Play'}
            </button>
        </div>
    );
};

export default VideoPlayer;